<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <b-row class="match-height">
            <b-col lg="12" md="12">
        
                <b-form @submit.prevent="formSubmit">
                    <b-card title="">

                        <b-row v-if="checkPermission('custom-forms', 'Update Item')">
                            <b-col md=12>
                                <div class="demo-inline-spacing float-right "> 
                                    <b-button variant="danger" class="mt-0 mb-1" :to="{ name: 'misconduct'}">
                                        <feather-icon icon="AlertCircleIcon" class="mr-25"/>
                                        <span>Misconduct</span>
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>

                        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                            <div class="alert-body">
                                {{error_message}}
                            </div>
                        </b-alert>

                        <b-row>
                            <b-col md="12">
                                <h4 class="card-title float-left"> Warning Letter </h4>
                            </b-col>
                        </b-row>

                        <b-row>

                            <b-col md="3">
                                <b-form-group label="Project Site" class="required">
                                    <b-form-select v-model="site" @change="siteChange" :disabled="disabledOptions">
                                        <b-form-select-option value="" disabled>Select</b-form-select-option>
                                        <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                                    </b-form-select>                                        
                                </b-form-group>
                            </b-col>

                            <b-col md="3">
                                <b-form-group label="Shift" class="required">
                                    <b-form-select v-model="shift" @change="getDraftData" :disabled="disabledOptions">
                                        <b-form-select-option value="" disabled>Select</b-form-select-option>
                                        <b-form-select-option :value="shift._id" v-for="shift in shifts" :key="shift._id">{{shift.shift | capitalize}}</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>                                
                            </b-col>

                            <b-col md="3">
                                <!-- <b-form-group label="Date - 日期 :" class="required"> -->
                                <b-form-group label="Date" class="required">
                                    <b-form-datepicker @input="getDraftData" v-model="form.warning_date" :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }" locale="en" :disabled="disabledOptions" />
                                </b-form-group>
                            </b-col>

                            <b-col md="3">
                                <b-form-group label="Submitted By" class="required">
                                    <b-form-input autocomplete="off" placeholder="3 to 100 characters" v-model="form.submitted_by" :disabled="disabledOptions" />
                                </b-form-group>
                            </b-col>

                        </b-row>
                    
                    </b-card>

                    <div v-if="site && shift">

                    <b-card>
                        
                        <b-row>
                            <b-col md="12">
                                <b-row>
                                    <b-col md="12">
                                        <p> Record of Warning : <small class="text-danger">*</small> <br/> 警告记录 : <small class="text-danger">*</small> </p>
                                        <b-row>
                                            <b-col cols="2" md="1"> 
                                                <b-form-checkbox
                                                    v-model="form.warning_1"
                                                    value="yes"
                                                    unchecked-value="no"
                                                    class="custom-control-info"
                                                    @change="updateWarning($event,'warning_1')"
                                                > 1st </b-form-checkbox>
                                            </b-col>
                                            <b-col cols="2" md="1">
                                                <b-form-checkbox
                                                    v-model="form.warning_2"
                                                    value="yes"
                                                    unchecked-value="no"
                                                    class="custom-control-warning"
                                                    @change="updateWarning($event,'warning_2')"
                                                > 2nd </b-form-checkbox>
                                            </b-col>
                                            <b-col cols="2" md="1">
                                                <b-form-checkbox
                                                    v-model="form.warning_3"
                                                    value="yes"
                                                    unchecked-value="no"
                                                    class="custom-control-danger"
                                                    @change="updateWarning($event,'warning_3')"
                                                > 3rd </b-form-checkbox>
                                            </b-col>
                                            <b-col cols="2" md="1">
                                                <b-form-checkbox
                                                    v-model="form.warning_final"
                                                    value="yes"
                                                    unchecked-value="no"
                                                    class="custom-control-secondary"
                                                    @change="updateWarning($event,'warning_final')"
                                                > Final </b-form-checkbox>
                                            </b-col>
                                        </b-row>
                                        <br>
                                    </b-col>                                    
                                    
                                    <b-col md="4">
                                        <b-form-group label="Name :" class="required mb-0"></b-form-group>
                                        <b-form-group label="名字 :" class="required">
                                            <!-- <b-form-input autocomplete="off" placeholder="3 to 100 characters" v-model="form.worker_name"/> -->
                                            <vue-simple-suggest
                                                v-model="form.worker_name"
                                                :list="nameList"
                                                :filter-by-query="true"
                                                placeholder="3 to 100 characters"
                                                :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkSimpleSuggest' : 'lightSimpleSuggest'"
                                            >
                                            </vue-simple-suggest>
                                        </b-form-group>
                                    </b-col>
                                    
                                    <b-col md="4">
                                        <b-form-group label="Department :" class="required mb-0"></b-form-group>
                                        <b-form-group label="部门 :" class="required">
                                            <b-form-input autocomplete="off" placeholder="3 to 100 characters" v-model="form.department"/>
                                        </b-form-group>
                                    </b-col>
                                    
                                    <b-col md="4">
                                        <b-form-group label="Position :" class="required mb-0"></b-form-group>
                                        <b-form-group label="职位 :" class="required">
                                            <b-form-input autocomplete="off" placeholder="3 to 100 characters" v-model="form.position"/>
                                        </b-form-group>
                                    </b-col>
                                    
                                </b-row>

                            </b-col>
                        </b-row>

                    </b-card>

                    <!-- All Defect Type -->
                    <b-card>
                        <b-row class="mb-1 mt-1">
                            <b-col md="12">
                                <h5> The above named employee has been warned verbally for displaying the following misconduct :</h5>
                                <h5> 上述雇员口头上以被警告他所犯了以下不当行为 : </h5>
                                <h5> Misconduct:(Check where applicable and specify details in section below) :</h5>
                                <h5> 不当行为分类有(请选有关空格) : </h5>
                                <br>
                            </b-col>
                        </b-row>
                        
                        <b-row class="mb-1 mt-1">
                            <b-col cols="1">
                                <h5> # </h5>
                            </b-col>
                            <b-col cols="9">
                                <h5> Misconduct </h5>
                            </b-col>
                            <b-col cols="2">
                                <h5> Action </h5>
                            </b-col>
                        </b-row>

                        <hr>

                        <div v-for="(mt, index) in form.misconduct" :key="index">
                            <b-row class="mb-0">
                                <b-col cols="1" class="mb-1"> {{index + 1}} </b-col>
                                <b-col cols="9" class="mb-1"> {{mt.misconduct_en}} - {{mt.misconduct_ch}} </b-col>
                                <b-col cols="2" class="mb-1"> 
                                    <b-form-checkbox
                                        v-model="mt.action"
                                        value="yes"
                                        unchecked-value="no"
                                        class="custom-control-danger"
                                    />
                                </b-col>
                            </b-row>
                            <hr v-if="form.misconduct.length != (index + 1)">
                        </div>
                    </b-card>

                    <b-card>
                        <b-row>
                            <b-col cols="12" class="mb-1" >
                                <b-form-group label="Please specify misconduct in detail :" class="mb-0 required"></b-form-group>
                                <b-form-group label="请注明详细的不当行为 :" class="required">
                                    <b-form-textarea rows="5" v-model="form.misconduct_in_detail" placeholder="Please specify misconduct in detail - 请注明详细的不当行为 ..."/>
                                </b-form-group>
                            </b-col> 

                            <b-col cols="12" class="mb-1" >
                                <b-form-group label="Action taken :" class="mb-0 required"></b-form-group>
                                <b-form-group label="采取的行动 :" class="required">
                                    <b-form-textarea rows="3" v-model="form.action_taken" placeholder="Action taken - 采取的行动: ..."/>
                                </b-form-group>
                            </b-col> 

                            <b-col md="6">
                                <b-form-group label="Written / Verbal given by :" class="mb-0 required"></b-form-group>
                                <b-form-group label="写/给予动词 :" class="required">
                                    <!-- <b-form-input autocomplete="off" placeholder="3 to 100 characters" v-model="form.written_verbal_given_by"/> -->
                                    <vue-simple-suggest
                                        v-model="form.written_verbal_given_by"
                                        :list="writtenVerbalList"
                                        :filter-by-query="true"
                                        placeholder="3 to 100 characters"
                                        :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkSimpleSuggest' : 'lightSimpleSuggest'"
                                    >
                                    </vue-simple-suggest>
                                </b-form-group>
                            </b-col>

                            <b-col md="6">

                                <b-form-group label="Crew Name :" class="mb-0 required"></b-form-group>
                                <b-form-group label="船员姓名 :" class="required">
                                    <b-form-input autocomplete="off" placeholder="3 to 100 characters" v-model="form.crew_name"/>
                                </b-form-group>
                            
                            </b-col>

                        </b-row>
                    </b-card>
                    
                    
                    
                    <!-- supervisor signature -->
                    <b-card>
                        
                        <b-row>
                            <b-col offset-md="3" md="3">
                                <b-form-group label="Submitted Date :" class="required mb-0"></b-form-group>                                
                                <b-form-group label="提交日期 :" class="required">
                                    <b-form-datepicker v-model="form.submitted_date" :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }" locale="en" :max="today" />
                                </b-form-group>
                                <br>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Submitted Time :" class="required mb-0"></b-form-group>                                
                                <b-form-group label="提交时间 :" class="required">
                                    <flat-pickr v-model="form.submitted_time" class="form-control" :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}" />
                                </b-form-group>
                                <br>
                            </b-col>
                        </b-row>

                        <b-row class="mb-2">
                            <!-- signature structure  -->
                            <b-col cols="6" class="text-center">
                                <h4 class="text-center pb-2">Crew Signature <span style="color:red">*</span></h4>
                                <div v-if="typeof $route.params.id != 'undefined'">
                                    <center>
                                        <img :src="form.crew_sign.image" v-if="form.crew_sign != null" class="rounded" style="background: #ffffff" width="200px" height="180">
                                    </center>
                                    
                                    <center>
                                        <b-button size="sm" variant="danger" class="mt-1 px-2 py-1" v-if="supervisorSign.image != null" :disabled="disabledOptions">
                                            <feather-icon icon="XIcon" class="mediumSize text-white"/>
                                        </b-button>
                                    </center>
                                </div>
                                <CrewSignature v-else/>
                            </b-col>

                            <b-col cols="6">
                                <h4 class="text-center pb-2">Supervisor Signature <span style="color:red">*</span></h4>
                                <center>
                                    <b-media class="" @click="openSignature('supervisor')" v-if="supervisorSign.image == null">                                      
                                        <b-avatar ref="supervisorS" :src="doc_icon" variant="light-info" rounded style="height: 200px; width: auto"/>

                                        <div class="d-flex flex-wrap">
                                            <input ref="supervisorSInput" type="file" class="d-none" @input="supervisorImageUpload" >
                                        </div>
                                    </b-media>
                                    <img :src="supervisorSign.image" v-if="supervisorSign.image != null" class="rounded" style="background: #ffffff" width="200px" height="180">
                                </center>
                                
                                <center>
                                    <b-button size="sm" variant="danger" class="mt-1 px-2 py-1" v-if="supervisorSign.image != null" @click="removeSign('supervisor')" :disabled="disabledOptions">
                                        <feather-icon icon="XIcon" class="mediumSize text-white"/>
                                    </b-button>
                                </center>
                            </b-col>

                            
                        </b-row>
                    
                        <b-row class="mt-2">
                            <b-col>

                                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" class = "mr-1" @click="goBack()">
                                    Cancel
                                </b-button>

                                <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" variant = "danger" class = "mr-1" @click="discardItem(form.id)" v-if="form.id != null && typeof $route.params.id == 'undefined'">
                                    Discard Draft
                                </b-button>

                                <!-- <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "primary" class = "mr-1" @click="form.status = 'draft'" :disabled="form.warning_1 == 'no' && form.warning_2 == 'no' && form.warning_3 == 'no' && form.warning_final == 'no' ? true : false"> -->
                                <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "primary" class = "mr-1" @click="form.status = 'draft'" v-if="typeof $route.params.id == 'undefined'">
                                    {{ form.id == null ? 'Save as Draft' : 'Update Draft'}}
                                </b-button>

                                <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "warning" class = "mr-1" @click="form.status = 'completed'">
                                    {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Save' }}
                                </b-button>

                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1" v-if="typeof $route.params.id != 'undefined' && ledit == false" @click="openSignature('approver')">
                                    {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Approve' }}
                                </b-button>

                            </b-col>
                        </b-row>
                    </b-card>

                    </div>

                </b-form>

            </b-col>
        </b-row>

        <b-modal
        id="signatureModel"
        ref="signatureModel"
        centered
        title="Signature"
        no-close-on-backdrop
        hide-footer
        @hide="hideSignature"
        @show="hideSignature"
      >
      
      <div v-if="show_default == true">
        <b-row>

          <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
            <center>
              <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
            </center>

          </b-col>
        </b-row>

        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">          
          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'administrator' ? false : true"
              :disabled-date="disabledDate"
            ></date-picker>
          </b-col>
        </b-row>

        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
			<b-col md="12">
				<b-form-group
					label="Remarks"
					class=""
				>
					<b-form-textarea
					placeholder=""
					rows="3"
					v-model="approve_remark"
					/>
				</b-form-group>
			</b-col>
		  </b-row>

        <b-row class="mt-2">

          <b-col md="6">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  @click="show_default = false"
                >
                <span class="text-nowrap">Add New Signature</span>
              </b-button>
            </b-col>

            <b-col md="6">
              <b-button
                  variant="warning"
                  class="mt-0 float-right w-100"
                  @click="saveDefault()"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row>
          <b-col md="12">
            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
            />

          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'administrator' ? false : true"
              :disabled-date="disabledDate"
              ></date-picker>

          </b-col>
        </b-row>

        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <b-form-group
                label="Remarks"
                class=""
              >
                <b-form-textarea
                  placeholder=""
                  rows="3"
                  v-model="approve_remark"
                />
              </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">

          <b-col md="3" v-if="typeof $route.params.id == 'undefined'">
            <b-button
                variant="primary"
                class="mt-0 w-100"
                @click="uploadSign()"
              >
              <span class="text-nowrap">Upload</span>
            </b-button>
          </b-col>

          <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  v-if="having_default == true"
                  @click="show_default = true"
                >
                <span class="text-nowrap">Default</span>
              </b-button>
            </b-col>


          <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

              <b-button
                  variant="danger"
                  class="mt-0 w-100"
                  @click="clearSignature"
                >
                <span class="text-nowrap">Clear</span>
              </b-button>
            </b-col>

            <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">
              <b-button
                  variant="warning"
                  class="mt-0 w-100"
                  @click="saveSignature"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>
      </b-modal>

    </div>
</template>
<script>
    import {
        BFormTextarea, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile,BFormDatepicker,BBreadcrumb
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive';
    import { GET_API, POST_API } from "../../../store/actions.type";
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker';
    import 'vue2-timepicker/dist/VueTimepicker.css';
    import vSelect from 'vue-select';
    import Bus from "../../../event-bus";
    import Datepicker from 'vuejs-datepicker';
    import flatPickr from 'vue-flatpickr-component';
    import VueSimpleSuggest from 'vue-simple-suggest'
    import 'vue-simple-suggest/dist/styles.css' // Optional CSS
    import CrewSignature from './CrewSignature.vue';
    var moment = require('moment-timezone');
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    export default {
        components: {
            CrewSignature, VueSimpleSuggest, flatPickr, BFormTextarea, BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, VueTimepicker, vSelect, BFormFile, BFormDatepicker,BBreadcrumb,DatePicker
        },

        directives: {
            Ripple,
        },

        data() {
            return {                  
                error_message:null,
                showDismissibleAlert:false,
                sites : null,
                shifts:[],
                site:'',
                shift:'',

                form :{
                    id : null,

                    warning_1     : 'no',
                    warning_2     : 'no',
                    warning_3     : 'no',
                    warning_final : 'no',

                    worker_name  : '',
                    warning_date : moment().tz('Asia/Singapore').format('YYYY-MM-DD'),
                    department : null,
                    position : null,
                    
                    misconduct : [],
                    misconduct_in_detail : null,
                    action_taken : null,
                    written_verbal_given_by : '',
                    submitted_by            : this.$store.getters.currentUser.full_name,
                    submitted_date          : ''/* moment().tz('Asia/Singapore').format('YYYY-MM-DD') */,
                    submitted_time          : ''/* moment().tz('Asia/Singapore').format('HH:mm') */,
                    status                  :'completed',

                    crew_name: '',
                    crew_sign : {
                        image:null,
                        name:'',
                        type: 'supervisor',
                        default:'no'
                    },
                },

                today : moment().tz('Asia/Singapore').format('YYYY-MM-DD'),

                // signature vars
                doc_icon: require('@/assets/images/doc.png'),
                supervisorSign : {
                    image:null,
                    name:'',
                    type: 'supervisor',
                    default:'no'
                },
                openedSignModel:null,

                flag: false,
                tempSign : {
                    image:null,
                    name:'',
                },
                show_default:false,
                having_default:false,

                nameList: [],
                writtenVerbalList: [],

                // new vars 
                disabledOptions:false,
                approve_signature_time:null,
                approve_remark:'',
                ledit : false,
                signature:{image:null,name:'',default:'no'},
                crew_signature : {
                    image:null,
                    name:'',
                    type: 'supervisor',
                    default:'no'
                },

            }
        },

        methods: {
            updateWarning(e, type){
                if(this.site && this.shift && this.form.warning_1 == 'no' && this.form.warning_2 == 'no' && this.form.warning_3 == 'no' && this.form.warning_final == 'no' ? false : true){
                    localStorage.setItem('routeCFCheck','yes')
                } else {
                    localStorage.setItem('routeCFCheck','no')
                }

                if (e == 'no') {
                    this.form.warning_1      = 'no';
					this.form.warning_2      = 'no';
					this.form.warning_3      = 'no';
					this.form.warning_final  = 'no';
				} else {
					if (e == 'yes' && type == 'warning_1') {
						this.form.warning_2     = 'no';
						this.form.warning_3     = 'no';
						this.form.warning_final = 'no';
                    }else if(e == 'yes' && type == 'warning_2'){
						this.form.warning_1     = 'no';
						this.form.warning_3     = 'no';
						this.form.warning_final = 'no';
					}else if(e == 'yes' && type == 'warning_3'){
						this.form.warning_1     = 'no';
						this.form.warning_2     = 'no';
						this.form.warning_final = 'no';
					}else if(e == 'yes' && type == 'warning_final'){
						this.form.warning_1 = 'no';
						this.form.warning_2 = 'no';
						this.form.warning_3 = 'no';
					}
				}
			},

            formSubmit(){
                if (this.form.status == 'completed') {
                    this.decisionAlert('Are you sure want to submit this report ?')
                    .then(result => {
                        if (result.value) {
                            this.actualForm();            
                        }
                    })
                }else{
                    this.actualForm();
                }
            },

            actualForm(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        site  :this.site,
                        shift : this.shift,
                        items : this.form,
                        supervisorSign : this.supervisorSign,
                        form_id : this.$route.params.id,
                        approve_signature : this.crew_signature,
                        approve_signature_time : this.approve_signature_time,
                        approve_remark: this.approve_remark,
                        role:this.$store.getters.currentUser.role,
                    },
                    api : '/api/add-warning-letter'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message        = this.$store.getters.getErrors;

                        this.errorAlertCF()
                    } else {
                        this.showDismissibleAlert = false;

                        var data = this.$store.getters.getResults.data;

                        this.successAlert().then((result) => {
                            
                            this.refreshData();

                            if (data != null) {
                                window.open(data, '_blank');
                            }

                            localStorage.setItem('routeCFCheck','no')

                            Bus.$emit('counter_update');
                            
                            // this.$router.go(-1);
                            // this.$router.push({ name:'custom-forms' })
                            if(this.$route.params.id){
                                this.$router.push({name:'warning-letter-report'})
                            }else{
                                this.$router.push({ name:'custom-forms' })
                            }
                        });
                    }
                });
            },
            
            getMisconducts(){
                return this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api: '/api/all-misconduct'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;

                        this.form.misconduct = data;
                        
                    }
                });
            },

            supervisorImageUpload(event){
                var input = event.target;
                var files = event.target.files

                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.readAsDataURL(input.files[0]);
                    var image = input.files[0];

                    if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
                        Swal.fire({
                            position: 'center',
                            icon: 'warning',
                            title: 'Please upload .jpg, .jpeg, .jpg images only',
                            showConfirmButton: false,
                            timer: 1500
                        });

                        this.$refs.supervisorSInput.value=null;

                    } else if (image.size > 2097152) {
                        Swal.fire({
                            position: 'center',
                            icon: 'warning',
                            title: 'Maximum 2 MB files allowed to be upload.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.$refs.supervisorSInput.value=null;
                    } else {
                        reader.onload = (e) => {
                            this.supervisorSign.image = e.target.result;
                            this.supervisorSign.name = image.name;
                            this.supervisorSign.type =  'supervisor';
                            this.supervisorSign.default = 'no';
                        }
                    }
                }
            },
                    
            removeSign(sign){
                this.supervisorSign.image = null;
                this.supervisorSign.name = '';
                this.supervisorSign.default = 'no';
            },

            getDraftData(){
                localStorage.setItem('routeCFCheck','no');

                return this.$store.dispatch(POST_API, {
                    data:{
                        site  : this.site != '' ? this.site : null,
                        shift : this.shift != '' ? this.shift : null,
                        warning_date : this.form.warning_date
                    },
                    api: '/api/draft-warning-letter-data'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;

                        if(data){
                            this.form.id                      = data.id;
                            this.form.warning_1               = data.warning_1;
                            this.form.warning_2               = data.warning_2;
                            this.form.warning_3               = data.warning_3;
                            this.form.warning_final           = data.warning_final;
                            this.form.worker_name             = data.worker_name;
                            this.form.warning_date            = data.warning_date;
                            this.form.department              = data.department;
                            this.form.position                = data.position;
                            this.form.misconduct              = data.id ? data.misconduct : this.getMisconducts();
                            this.form.misconduct_in_detail    = data.misconduct_in_detail;
                            this.form.action_taken            = data.action_taken;
                            this.form.written_verbal_given_by = data.written_verbal_given_by;
                            this.form.submitted_by            = data.submitted_by ? /* data.submitted_by */this.$store.getters.currentUser.full_name : this.$store.getters.currentUser.full_name;
                            this.form.submitted_date          = data.submitted_date;
                            this.form.submitted_time          = data.submitted_time;
                            this.form.status                  = data.status;
                            this.supervisorSign               = data.supervisorSign;
                            this.form.crew_name               = data.crew_name;
                            this.form.crew_sign               = data.crew_sign;

                            Bus.$emit('update-crew-image-incoming', {data: this.form.crew_sign});
                        }
                    }
                });
            },

            discardItem(id){
                var msg = 'Are you sure want to discard this record?';

                this.decisionAlert(msg)
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                id     : id,
                                status : 'deleted'
                            },
                            api : "/api/change-status-warning-letter-data",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;

                                this.showAlert();
                            } else {
                                this.successAlert()
                                .then(() => {
                                    this.flag = true;
                                    this.refreshData();
                                    localStorage.setItem('routeCFCheck','no')
                                    // this.$router.go(-1);
                                    this.$router.push({ name:'custom-forms' })
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },

            refreshData(){
                if(this.flag){
                    this.flag = false;
                    this.form.id = null;
                }
                this.form.submitted_by = this.$store.getters.currentUser.full_name;
                this.form.submitted_date = ''/* moment().tz('Asia/Singapore').format('YYYY-MM-DD') */;

                this.supervisorSign = {
                    image:null,
                    name:'',
                    type: 'supervisor',
                    default:'no'
                };
                
                this.openedSignModel=null;
            },
            
            // signature block function starts 
                useDefault(){

                return this.$store.dispatch(POST_API, {
                    data:{
                        id:this.$store.getters.currentUser._id
                    },
                    api: '/api/get-default-signature'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showDismissibleAlert = true;
                            window.scrollTo(0,0);
                        } else {
                            this.showDismissibleAlert = false;
                            var data  = this.$store.getters.getResults.data;
                            
                            if (data == null || data.default_signature == null) {
                            
                            this.having_default = false;
                            
                            }else{

                            this.tempSign = {
                                image : data.default_signature,
                                name : 'siganture.png',
                            }

                            this.having_default = true;
                            }
                        }
                    });
                },
                uploadSign(){
                
                //if (this.openedSignModel == 'supervisor') {

                    this.$refs['supervisorSInput'].click();
                //}

                /*if (this.openedSignModel == 'inspected_by') {

                    this.$refs['inspectedSInput'].click();
                }*/

                this.$refs['signatureModel'].hide();

                },
                openSignature(type){
                    this.approve_signature_time = moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY HH:mm');
                    if (this.openedSignModel == null) {

                        if (this.having_default == true) {
                            this.show_default = true;
                        }else{
                            this.show_default = false;
                        }

                        this.$refs['signatureModel'].show();
                        this.openedSignModel = type;
                    }
                },
                hideSignature(){
                    this.openedSignModel = null;
                },
                clearSignature(){
                    this.$refs.signaturePad.clearSignature();
                },
                saveSignature(){
                    const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
                    
                    if (isEmpty) {
                        Swal.fire({
                            position: 'center',
                            icon: 'warning',
                            title: 'Signature is required',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }else{
                        if (this.openedSignModel == 'supervisor') {
                            this.supervisorSign.image = data;
                            this.supervisorSign.name = 'signature.png';
                            this.supervisorSign.type = 'supervisor';
                            this.supervisorSign.default = 'no';
                        } else {
                            this.crew_signature.image = data;
                            this.crew_signature.name = 'signature.png';
                            this.crew_signature.type = 'supervisor';
                            this.crew_signature.default = 'no';
                            this.form.status = 'approved';
                            this.actualForm();
                        }
                        this.$refs['signatureModel'].hide();
                    }
                },

                saveDefault(){
                    if (this.openedSignModel == 'supervisor') {
                        this.supervisorSign = {
                            image : this.tempSign.image,
                            name : 'signature.png',
                            default:'yes'
                        }
                    }else{
                        this.crew_signature = {
                            image : this.tempSign.image,
                            name : 'signature.png',
                            default:'yes',
                            type:'supervisor'
                        }
                        this.form.status = 'approved';
                        this.actualForm();
                    }
                    this.$refs['signatureModel'].hide();
                },
            // signature block function ends

            breadCrumb(){
                if(this.$route.params.id){
                    var item = [{
                        to:{name:'client-dashboard'},
                        text: 'Dashboard',
                    },{
                        to:null,
                        text: 'Reports',
                    },{
                      to:{name:'custom-forms-dashboard'},
                      text: 'Form Dashboard',
                    },{
                        to:{name:'custom-forms-reports'},
                        text:'Custom Forms'
                    },{
                        to:{name:'warning-letter-report'},
                        text:'Warning Letter Report'
                    },{
                        to:null,
                        text:'Edit',
                        active:true
                    }];
                    return this.filterReportBreadCrum(item);
                } else {
                    var item = [{
                        to:{name:'client-dashboard'},
                        text: 'Dashboard',
                    },{
                        to:{name:'custom-forms'},
                        text: 'Custom Forms',
                    },{
                        to:null,
                        text:'Warning Letter',
                        active:true
                    }];
                }              
                return item;
            },

            goBack(){
                if (this.form.warning_1 == 'no' && this.form.warning_2 == 'no' && this.form.warning_3 == 'no' && this.form.warning_final == 'no' ? false : true) {
                    var msg = 'Do you want to Go back without saving your report?';
                    this.decisionAlert(msg)
                    .then(result => {
                        if (result.value) {
                            localStorage.setItem('routeCFCheck','no');
                            // this.$router.go(-1);
                            //this.$router.push({ name:'custom-forms' })
                            if(this.$route.params.id){
                                this.$router.push({name:'warning-letter-report'})
                            }else{
                                this.$router.push({ name:'custom-forms' })
                            }
                        }
                    })
                }else{
                    // this.$router.go(-1);
                    this.$router.push({ name:'custom-forms' })                   
                }
            }, 

            allSites(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        role:this.$store.getters.currentUser.role,
                        //om_sites:this.$store.getters.currentUser.om_sites,
                    },
                    api: '/api/all-sites'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        this.sites = data;

                        if (this.sites.length == 1 && typeof this.$route.params.id == 'undefined') {
                                    
                            this.site = this.sites[0]._id;
                            this.siteChange();
                        }

                        return this.sites;
                    }
                });
            },

            siteChange(){
                this.checklistData = [];
                this.shifts = [];
                this.shift = '';
                this.form.submitted_by = this.$store.getters.currentUser.full_name;

                if (this.site != '') {
                    this.getShiftData();
                    this.getDraftData();
                }

                this.nameSuggestionList(['crew', 'supervisor']);
                this.writtenVerbalSuggestionList(['supervisor', 'operation_manager']);
            },

            getShiftData(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        site:this.site != '' ? this.site : null,
                    },
                    api: '/api/site-shifts'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        this.shifts = data;
                        return this.shifts;
                    }
                });
            },

            nameSuggestionList(roles) {        
                return this.$store.dispatch(POST_API, {
                    data:{ roles: roles, site: this.site != '' ? this.site : null, },
                    api: '/api/user-suggestions-by-role'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                    } else {
                        this.nameList = this.$store.getters.getResults.data;                        
                    }
                });
            },

            writtenVerbalSuggestionList(roles) {
                return this.$store.dispatch(POST_API, {
                    data:{ roles: roles, site: this.site != '' ? this.site : null, },
                    api: '/api/user-suggestions-by-role'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                    } else {
                        this.writtenVerbalList = this.$store.getters.getResults.data;                        
                    }
                });
            },

            getFormDetails(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        id:this.$route.params.id
                    },
                    api: '/api/get-warning-letter-report-detail'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;

                        this.form.id                      = data._id;
                        this.site                         = data.site;
                        this.getShiftData();
                        this.shift                        = data.shift;
                        this.form.warning_1               = data.warning_1;
                        this.form.warning_2               = data.warning_2;
                        this.form.warning_3               = data.warning_3;
                        this.form.warning_final           = data.warning_final;
                        this.form.worker_name             = data.worker_name;
                        this.form.warning_date            = data.warning_date;
                        this.form.department              = data.department;
                        this.form.position                = data.position;
                        this.form.misconduct              = data.misconduct;
                        this.form.misconduct_in_detail    = data.misconduct_in_detail;
                        this.form.action_taken            = data.action_taken;
                        this.form.written_verbal_given_by = data.written_verbal_given_by;
                        this.form.submitted_by            = data.submitted_by;
                        this.form.submitted_date          = data.submitted_date;
                        this.form.submitted_time          = data.submitted_time;
                        this.form.status                  = data.status;
                        //this.supervisorSign               = data.supervisorSign;
                        this.form.crew_name               = data.crew_name;
                        // this.form.crew_sign               = data.crew_sign;

                        if(data && data.signature){
                            this.supervisorSign = {
                                image   : data.signature,
                                name    : 'signature.png',
                                type    : 'supervisor',
                                default : 'yes'
                            }
                        } else {
                            this.supervisorSign = {
                                image   : this.tempSign.image,
                                name    : 'signature.png',
                                type    : 'supervisor',
                                default : 'yes'
                            }
                        }

                        if(data.crew_sign){
                            this.form.crew_sign = {
                                image   : data.crew_sign,
                                name    : 'signature.png',
                                type    : 'supervisor',
                                default : 'yes'
                            }
                        }

                        // Bus.$emit('update-crew-image-incoming', {data: this.form.crew_sign, disable: this.disabledOptions});

                        var role = this.$store.getters.currentUser.role;

                        if (data.reject_to == role && ((data.level_one_user_role != role) && (data.level_two_user_role != role) && (data.level_three_user_role != role))) {
                            this.ledit = true;
                        } else if(((role == 'admin') || (role == 'administrator')) && ( (data.level_user_status != null) && (data.level_user_status.role == ''))){
                            this.ledit = true;
                        }
                    }
                });

            },

            disabledDate(date){
                return date < moment(new Date(this.form.warning_date)).subtract(1,'days').tz('Asia/Singapore');
            },

            checkdisabled(){
                if (this.ledit == true) {
                    return false;
                } else if (this.disabledOptions == true){
                    return true;
                }else{
                    return false;
                }
            },

        },

        mounted(){
            this.getMisconducts();
            this.allSites();
            this.siteChange();

            this.useDefault();

            Bus.$on('update-crew-image', (data) => {
                this.form.crew_sign = data.data;
            })

            // to delete crew sign
            Bus.$on('remove-crew-image', (data) => {
                this.form.crew_sign = {
                    default:'no',
                    name:'',
                    image:null,
                };
            })

            // this.nameSuggestionList(['crew', 'supervisor']);
            // this.writtenVerbalSuggestionList(['supervisor', 'operation_manager']);
            // this.getDraftData();

            if(this.$route.params.id){
                this.disabledOptions = true;
                this.getFormDetails();
            }
        }
    }
</script>
<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>